<template>
  <div>
    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12">
        <v-card class="border-radius-lg mt-5">
          <v-row class="justify-space-between pt-3 pb-5 px-7">
            <div>
              <h5 class="text-h5 my-2 font-weight-bold text-typo">
                Rechtliches
              </h5>
            </div>
          </v-row>
          <div>
            <v-divider class="bg-light"></v-divider>
            <v-row
              class="align-center justify-space-between pa-0 ma-0 pa-3"
              style="cursor: pointer"
              @click="openLink('impressum')"
            >
              <div class="mr-2 pt-1">Impressum</div>
              <div class="mr-3">
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </div>
            </v-row>
          </div>
          <div>
            <v-divider></v-divider>
            <v-row
              class="align-center justify-space-between pa-0 ma-0 pa-3"
              style="cursor: pointer"
              @click="openLink('datenschutz')"
            >
              <div class="mr-2 pt-1">Datenschutz</div>
              <div class="mr-3">
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </div>
            </v-row>
          </div>
          <div>
            <v-divider></v-divider>
            <v-row
              class="align-center justify-space-between pa-0 ma-0 pa-3"
              style="cursor: pointer"
              @click="openLink('agb')"
            >
              <div class="mr-2 pt-1">AGB</div>
              <div class="mr-3">
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </div>
            </v-row>
          </div>
          <div>
            <v-divider></v-divider>
            <v-row
              class="align-center justify-space-between pa-0 ma-0 pa-3"
              style="cursor: pointer"
              @click="openLink('widerrufsbelehrung')"
            >
              <div class="mr-2 pt-1">Widerruf</div>
              <div class="mr-3">
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </div>
            </v-row>
          </div>
        </v-card></v-col
      ></v-row
    >
    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12">
        <v-card class="border-radius-lg mt-2">
          <v-row class="justify-space-between pt-3 pb-5 px-7">
            <div>
              <h5 class="text-h5 my-2 font-weight-bold text-typo">
                Kundenservice
              </h5>
            </div>
          </v-row>
          <div>
            <v-divider></v-divider>
            <v-row
              class="align-center justify-space-between pa-0 ma-0 pa-3"
              style="cursor: pointer"
              @click="openLink('howto')"
            >
              <div class="mr-2 pt-1">Fragen & Antworten</div>
              <div class="mr-3">
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </div>
            </v-row>
          </div>
          <div>
            <v-divider></v-divider>
            <v-row
              class="align-center justify-space-between pa-0 ma-0 pa-3"
              style="cursor: pointer"
              @click="openLink('kontakt')"
            >
              <div class="mr-2 pt-1">Kontaktiere uns</div>
              <div class="mr-3">
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </div>
            </v-row>
          </div>
          <div>
            <v-divider></v-divider>
            <v-row
              class="align-center justify-space-between pa-0 ma-0 pa-3"
              style="cursor: pointer"
              @click="openLink('kontakt')"
            >
              <div class="mr-2 pt-1 text-warning">Telefonsupport</div>
              <div class="mr-3">
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </div>
            </v-row>
          </div></v-card
        ></v-col
      ></v-row
    >
    <!-- <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12">
        <v-card class="border-radius-lg mt-2">
          <v-row class="align-center justify-space-between pa-0 ma-0 pa-3">
            <div class="mr-2 pt-1 text-h5 font-weight-bold text-typo">
              Entwickler
            </div>
            <div class="mr-3">
              <v-icon size="12">fas fa-chevron-right</v-icon>
            </div>
          </v-row>
        </v-card></v-col
      ></v-row
    > -->
    <!-- <v-card class="border-radius-lg mt-2 mx-5">
      <v-list-item style="min-height: 60px" @click="downloadAPK()">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bolder text-md"
            >APK Download</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon>
          <div>
            <v-icon class="text-secondary">fas fa-download</v-icon>
          </div>
        </v-list-item-icon>
      </v-list-item>
    </v-card> -->
  </div>
</template>

<script>
export default {
  name: "Settings",
  components: {},
  data() {
    return {};
  },
  methods: {
    downloadAPK() {
      window.open("https://app.soap-software.de/apk/soap-1.0.0.apk", "_blank");
    },
    openLink(link) {
      window.open(`https://soap-software.de/${link}`, "_blank");
    },
  },
};
</script>
